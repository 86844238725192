const dateHelper = require('../dateHelper');

module.exports = {
    convert
}

function convert(legacyDataString) {
    var conversionResult = new Object;

    conversionResult.type = null;
    conversionResult.data = null;

    //parse out the string to get the legacy type
    var commaPos = legacyDataString.indexOf(",");
    var recPos = legacyDataString.indexOf("PERRLA_REC_SEP");

    //if we didn't find either one, bail
    if ((commaPos == -1) || (recPos == -1)) {
        return type;
    }

    var legacyTypeId = legacyDataString.substring(commaPos + 1, recPos);

    var conversionData = require('./LegacyConversionTypes.json');

    //hacks to put in variables
    let beginningPageNumber = '';
    let endingPageNumber = '';
    let groupAuthor = null;
    let exactDate = ''
    let interviewer = '';
    let personalCommDate = '';
    let personalCommYear = '';
    let personName = '';
    let universityName = '';
    let songArtist = '';
    let screenName = '';
;
    conversionData.forEach(thisConversionType => {

        if (thisConversionType.legacyTypeID == legacyTypeId) {
            conversionResult.type = thisConversionType.genesisTypeID;
            conversionResult.data = new Object;

            var recordsArray = legacyDataString.split("PERRLA_REC_SEP");
            recordsArray.shift(); //remove the first entry that contains the type information

            recordsArray.forEach(thisRecord => {
                //skip any empty records
                if (thisRecord != '') {
                    var docArray = thisRecord.split("PERRLA_DOC_SEP");

                    var itemType = docArray[0];
                    var itemTypeId = docArray[1];
                    var data = docArray[2];

                    //see of this is a "hack" property to merge
                    if (itemType == '1') {
                        switch (itemTypeId) {
                            case '71':
                                beginningPageNumber = data;
                                break;
                        
                            case '72':
                                endingPageNumber = data;
                                break;
    
                            case '241':
                            case '230':
                                exactDate = data;
                                break;
    
                            case '445':
                                interviewer = data;
                                break;

                            case '225':
                                personalCommDate = data;
                                break;

                            case '244':
                                personalCommYear = data;
                                break;

                            case '224':
                                personName = data;
                                break;

                            case '107':
                                universityName = data;
                                break;

                            case '148':
                                songArtist = data;
                                break;

                            case '125':
                                screenName = data;
                                break;
                            
                            //uncomment this if you want to see a specific item when you debug
                            // case '163':
                            //     console.log('Found It');
                            //     break;
                        }                        
                    }

                    let thisField = null;
                    let isIdent = false;
                    thisConversionType.dataMapping.forEach(thisMapping => {
                        //name maker fields are stored differently for lookups....just items
                        let thisLegacyItemTypeID = thisMapping.legacyItemTypeID;

                        if (thisLegacyItemTypeID == 5 || thisLegacyItemTypeID == 6) {

                            if (thisLegacyItemTypeID == 6) {
                                isIdent = true;
                            }

                            thisLegacyItemTypeID = 1;
                        }

                        if (thisLegacyItemTypeID == itemType &&
                            thisMapping.legacyItemID == itemTypeId) {
                            thisField = thisMapping.genesisItemID;
                        }
                    });

                    if (thisField === null) {

                        if (itemType == 1) {
                            console.log('Mapping Not Found For: ' + itemType + '-' + itemTypeId);    
                        }
                        
                    } else {
                        //see if this is a contributor property
                        if (thisField.includes('contributor.')) {
                            
                            var typeArray = thisField.split(".");
                            var contributorType = typeArray[1];

                            var namesArray = data.split("~");

                            let arraySize = 3;

                            if (isIdent) {
                                arraySize = 4;
                            }

                            var nameCount = (namesArray.length - 1) / arraySize;

                            if (typeof conversionResult.data.contributors === 'undefined' && itemTypeId != '445') {
                                conversionResult.data.contributors = [];
                            }

                            if (contributorType == 'groupAuthor') {
                                if (groupAuthor == null)
                                {
                                    groupAuthor = Object();
                                    groupAuthor["type"] = "groupAuthor";
                                }

                                if (typeArray.length == 2)
                                {
                                    groupAuthor["groupName"] = data;
                                }
                                else
                                {
                                    groupAuthor[typeArray[2]] = data;
                                }

                            }
                            else{
                                var i = 0;

                                var firstNameCounter = 0;
                                var middleNameCounter = 1;
                                var lastNameCounter = 2;

                                if (nameCount > 0) {
                                    do {
                                        var contributor = new Object;
                                        contributor.type = contributorType;
                                        contributor.firstName = namesArray[firstNameCounter];
                                        contributor.middleName = namesArray[middleNameCounter];
                                        contributor.lastName = namesArray[lastNameCounter];
                                        contributor.suffix = '';
        
                                        conversionResult.data.contributors.push(contributor);
        
                                        firstNameCounter = firstNameCounter + arraySize;
                                        middleNameCounter = middleNameCounter + arraySize;
                                        lastNameCounter = lastNameCounter + arraySize;
        
                                        i++;
                                    }
                                    while (i < nameCount);                                
                                }
                            }
                        } else {

                            if (thisField.includes('publisher.')) {
                                var publisher = new Object;
                                var publisherType = thisField.replace('publisher.', '');

                                if (typeof conversionResult.data.publishers === 'undefined') {
                                    conversionResult.data.publishers = [];
                                }

                                publisher.type = publisherType;
                                publisher.name = data;

                                conversionResult.data.publishers.push(publisher);

                            }else{
                                if (thisField.length > 0) {

                                    if (typeof conversionResult.data[thisField] === 'undefined') {
                                        conversionResult.data[thisField] = data; 
                                    }
                                    else{
                                        if (conversionResult.data[thisField].length == 0) {
                                            conversionResult.data[thisField] = data;
                                        }
                                    }   
                                }
                            }
                        }
                    }
                }
            })

            //populate types and kinds
            conversionResult.data.type = thisConversionType.referenceType;
            conversionResult.data.kind = thisConversionType.referenceKind;
        }
    });

    //repopulate any "hacks"
    let pageNumbers = "";

    if (beginningPageNumber.length > 0 || endingPageNumber.length > 0)
    {
        pageNumbers = beginningPageNumber;

        if (beginningPageNumber.length > 0 && endingPageNumber.length > 0)
        {
            pageNumbers = pageNumbers + "–";
        }

        pageNumbers = pageNumbers + endingPageNumber;
    }

    if (pageNumbers.length > 0) {
        conversionResult.data['referencePages'] = pageNumbers;
    }

    if (personalCommDate.length > 0 || personalCommYear.length > 0) {

        try {
            let formattedExactDate = dateHelper.format(personalCommDate + ' ' + personalCommYear, 'm/dd/yyyy');
            conversionResult.data['publicationDate'] = formattedExactDate;
        }
        catch(err) {
            console.log('Failed to convert exact date of ' + exactDate);
        }
    }


    if (exactDate.length > 0) {

        try {
            //don't do this with blogs
            if (conversionResult.type != 11) {
                let formattedExactDate = dateHelper.format(exactDate + ' 2019', 'm/dd');
                conversionResult.data['publicationDate'] = formattedExactDate + '/' + conversionResult.data['publicationDate'];    
            }
        }
        catch(err) {
            console.log('Failed to convert exact date of ' + exactDate);
        }
    }
     
    if (groupAuthor != null) {
        if (typeof conversionResult.data.contributors === 'undefined') {
            conversionResult.data.contributors = [];
        }

        conversionResult.data.contributors.push(groupAuthor);
    }

    if (interviewer.length > 0) {
        if (typeof conversionResult.data.contributors === 'undefined') {
            conversionResult.data.contributors = [];
        }

        let firstName = '';
        let middleName = '';
        let lastName = '';

        var nameArray = interviewer.split(" ");

        switch (nameArray.length) {
            case 1:
                lastName = nameArray[0];
                break;
            case 2:
                firstName = nameArray[0];
                lastName = nameArray[1];
                break;
            case 3:
                firstName = nameArray[0];
                middleName = nameArray[1];
                lastName = nameArray[2];
                break;
        }


        let interviewerContributor = new Object;
        interviewerContributor.type = 'author';
        interviewerContributor.firstName = firstName;
        interviewerContributor.middleName = middleName;
        interviewerContributor.lastName = lastName;
        interviewerContributor.suffix = '';

        conversionResult.data.contributors.push(interviewerContributor);

    }

    if (songArtist.length > 0) {
        if (typeof conversionResult.data.contributors === 'undefined') {
            conversionResult.data.contributors = [];
        }

        let firstName = '';
        let middleName = '';
        let lastName = '';

        var nameArray = songArtist.split(" ");

        switch (nameArray.length) {
            case 1:
                lastName = nameArray[0];
                break;
            case 2:
                firstName = nameArray[0];
                lastName = nameArray[1];
                break;
            case 3:
                firstName = nameArray[0];
                middleName = nameArray[1];
                lastName = nameArray[2];
                break;
        }


        let artistContributor = new Object;
        artistContributor.type = 'author';
        artistContributor.firstName = firstName;
        artistContributor.middleName = middleName;
        artistContributor.lastName = lastName;
        artistContributor.suffix = '';

        conversionResult.data.contributors.push(artistContributor);

    }

    if (screenName.length > 0) {
        if (typeof conversionResult.data.contributors === 'undefined') {
            conversionResult.data.contributors = [];
        }

        let screenNameContributor = new Object;

        if (conversionResult.data.contributors.length > 0) {
            screenNameContributor = conversionResult.data.contributors[0];
            screenNameContributor.name = screenName;
        }
        else{
            screenNameContributor.type = 'userName';
            screenNameContributor.firstName = '';
            screenNameContributor.middleName = '';
            screenNameContributor.lastName = '';
            screenNameContributor.suffix = '';
            screenNameContributor.prefix = '';
            screenNameContributor.name = screenName;
            
            conversionResult.data.contributors.push(screenNameContributor);
        }
    }





    if (personName.length > 0) {
        if (typeof conversionResult.data.contributors === 'undefined') {
            conversionResult.data.contributors = [];
        }

        let firstName = '';
        let middleName = '';
        let lastName = '';

        var nameArray = personName.split(" ");

        switch (nameArray.length) {
            case 1:
                lastName = nameArray[0];
                break;
            case 2:
                firstName = nameArray[0];
                lastName = nameArray[1];
                break;
            case 3:
                firstName = nameArray[0];
                middleName = nameArray[1];
                lastName = nameArray[2];
                break;
        }


        let personContributor = new Object;
        personContributor.type = 'author';
        personContributor.firstName = firstName;
        personContributor.middleName = middleName;
        personContributor.lastName = lastName;
        personContributor.suffix = '';

        conversionResult.data.contributors.push(personContributor);

    }

    if (conversionResult.type == 2 && conversionResult.data.type == 'bookChapter' && conversionResult.data.kind == 'journal') {
        conversionResult.data.articleTitle = conversionResult.data.chapterTitle;
    }

    //for these two specific types, add a GPO publisher
    if (legacyTypeId == 976 || legacyTypeId == 86) {
        var publisher = new Object;
        
        if (typeof conversionResult.data.publishers === 'undefined') {
            conversionResult.data.publishers = [];
        }

        publisher.type = 'reference';
        publisher.name = 'Government Printing Office';

        conversionResult.data.publishers.push(publisher);
    }

    //for manuscripts, we need to to tweak the publisher to be reference type, and amke the data be Department Name, University Name
    if (legacyTypeId == 100 || legacyTypeId == 99 || legacyTypeId == 804 || legacyTypeId == 102)
    {
        if (typeof conversionResult.data.publishers != 'undefined') {
            conversionResult.data.publishers[0].type = 'reference';

            if (universityName.length > 0) {
                conversionResult.data.publishers[0].name = conversionResult.data.publishers[0].name + ', ' + universityName;    
            }
        }
    }

    return conversionResult;
}