// RS_Compress

import LzString from 'lz-string';
import store from '@/store';

export default ($opts) => {
	return new Promise((resolve) => {
		// console.log('RS_Compress');

		let compressedRefLibCompString = '';

		if($opts.isGenesis){
			compressedRefLibCompString = LzString.compressToUTF16(JSON.stringify(store.state.referenceLibraryGenesis));
			store.commit('REFERENCE_LIBRARY_GENESIS_COMPRESSED_STRING', compressedRefLibCompString);
		} else {
			compressedRefLibCompString = LzString.compressToUTF16(JSON.stringify(store.state.referenceLibrary));
			store.commit('REFERENCE_LIBRARY_COMPRESSED_STRING', compressedRefLibCompString);
		}
		
		resolve();

	});//e:Promise			
}
