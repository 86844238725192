// RS_LoadByRoute
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import AS_SyncData from '@/services/app/syncData';
import config from '@/config';
import ErrS_LoadByRoute from '@/services/app/error/load-by-route';
import ReferenceTypesData from '@/services/reference/types';
import router from '@/router';
import store from '@/store';

export default () =>{
	return new Promise((resolve) => {
		// console.log('RS_LoadByRoute');
		
		let _isStealthSync = false;

		// 2024.11.08 - start moving the loader into context specific positions, so for now the Editor Tab > Tool Drawer will have it's on loader
		if(router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT){
			if(router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
				
				store.commit('loaders/ADD_ID', 'EditorTabToolDrawer');

				_isStealthSync = true;
			}
		}

		AS_SyncData({
			isStealthSync: _isStealthSync
		}).then(()=>{
			let findReference;

			// look reference up by the referenceUniqueId param
			try {
				findReference = store.state.referenceLibraryGenesis.find((referenceData)=>{
					return referenceData.referenceUniqueID.toUpperCase() === router.currentRoute.params.referenceUid.toUpperCase()
				});

				if(findReference){
					// set this class as the open object
				store.commit('referenceEdit/SET_OPEN_OBJECT', findReference)
					store.commit('referenceRules/SET_REFERENCE_TYPE_ID', findReference.referenceTypeID);
					
					// now look up this reference type from types.json so i'll have it's name
					let findRefType = ReferenceTypesData.refTypes.find((refType)=>{
						return refType.id === findReference.referenceTypeID;
					});
					if(findRefType){
						// store.commit('referenceRules/SET_REFERENCE_TYPE_ID', findRefType.id);
						store.commit('referenceRules/SET_REFERENCE_TYPE_NAME', findRefType.title);
					}

					if(router.currentRoute.meta.module === config.enums.Module.PAPER_EDIT){
						if(router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
							store.commit('loaders/REMOVE_ID', 'EditorTabToolDrawer');
						}
					}
					
					store.commit('loaders/REMOVE_ID', 'App');
					return resolve();
					
				} else {
					ErrS_LoadByRoute({
						entityType: 'Reference',
						error: 'reference not found by its uid',
					});
				}

			} catch (error) {
				ErrS_LoadByRoute({
					entityType: 'Reference',
					error: error,
				});
			}
			
		});//e:AS_SyncData
	
	});//e:Promise

}