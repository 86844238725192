// RS_Prepare
// should be called twice once for Genesis and another time for non-Genesis references
// 2023.06.01 

import LzString from 'lz-string';
import store from '@/store';

export default ($opts) => {
	return new Promise((resolve) => {
		// console.log('RS_Prepare');

		let currentRefLibCompString;
		let currentRefLibArray = [];

		// fork here for Genesis or Regular
		if($opts.isGenesis){
			// Genesis Only
			// console.log('store.state.refLibCompStringGenesis');
			// console.log(store.state.refLibCompStringGenesis);

			if(store.state.refLibCompStringGenesis === '') {
				// could be the first time a user is using the new compression, or they just have no references in their paper
				currentRefLibArray = [];
			} else {
				currentRefLibCompString = LzString.decompressFromUTF16(store.state.refLibCompStringGenesis);
				currentRefLibArray = JSON.parse(currentRefLibCompString);
			}
		} else {
			// Non Genesis
			// console.log('store.state.refLibCompString');
			// console.log(store.state.refLibCompString);

			if(store.state.refLibCompString === '') {
				// could be the first time a user is using the new compression, or they just have no references in their paper
				currentRefLibArray = [];
			} else {
				currentRefLibCompString = LzString.decompressFromUTF16(store.state.refLibCompString);
				currentRefLibArray = JSON.parse(currentRefLibCompString);
			}
		}

		resolve(currentRefLibArray);

	});//e:Promise
}