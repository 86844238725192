/*
- RS_UpdateAfterSync
-- After Sync some references could have been updated in a different platform, use the array of updated references to update other data
-- For PERRLA Online this would update citations in a ckeditor instance
-- For the Add-In this just updates research note text
-- 2023.08.01
*/
import _forEach from 'lodash/forEach';
import config from '@/config';
import Engine from '@/services/reference/Engine';
import store from '@/store';

export default () => {
	return new Promise((resolve) => {
		// console.log('RS_UpdateAfterSync');

		if(store.state.references.updateAfterSync.length > 0){
			// console.log('refs to update');

			// Loop through references that need updating
			store.state.references.updateAfterSync.forEach((updatedReference) =>{
				// console.log('updatedReference');
				// console.log(updatedReference);
				
				// update research notes
				updatedReference.researchNotes.forEach((researchNoteData)=>{
					if(!researchNoteData.isDeleted){
						// citation (maybe a contributor changed)
						if(researchNoteData.citation === ''){
							// no citation - send a delete signal to see what happens
							store.commit('researchNotes/REMOVE_CITATION_BY_UNIQUE_ID', researchNoteData.genesisResearchNoteUniqueID); 							
						} else {
							// citation - update
							let modeLevel = 'available';
							if(config.isDev){
								modeLevel = 'dev';

							} else if(config.isTest){
								modeLevel = 'test';
								
							}
							
							let citationResultObject = JSON.parse(researchNoteData.citation);
							let updatedCitationData = Engine.getCitation(updatedReference.referenceTypeID, JSON.parse(updatedReference.data), citationResultObject.citationData, modeLevel);
							updatedCitationData.citationData = citationResultObject.citationData;

							store.commit('researchNotes/UPDATE_CITATION_BY_UNIQUE_ID', {
								newCitationData: updatedCitationData,
								researchNoteUniqueId: researchNoteData.genesisResearchNoteUniqueID,
							}); 
						}
										
						// text
						store.commit('researchNotes/UPDATE_TEXT_BY_UNIQUE_ID', {
							newNoteText: researchNoteData.note,
							researchNoteUniqueId: researchNoteData.genesisResearchNoteUniqueID,
						});
					}
				});//e:forEach.researchNote

				// ONLINE - update any citations incase a paper is open right now
				if(config.platformId === config.enums.Platform.ONLINE){
					// loop through each ckInstance
					_forEach(CKEDITOR.instances, (ckInstance)=>{
						// get all citations from this reference
						let $citationsNodeList = ckInstance.document.find('span[data-reference-unique-id="' + updatedReference.referenceUniqueID.toUpperCase() + '"]');

						if ($citationsNodeList.count() > 0) {
							
							// loop through citation nodes
							for (let i = 0; i < $citationsNodeList.count() ; i++) {
								// get each citation element
								let $thisCitationEl = $citationsNodeList.getItem(i);

								// get this citations uid from the data attribute
								let citationUniqueID = $thisCitationEl.getAttribute('data-citation-unique-id');

								let findCitationIndex = store.state.paperEdit.citations.inPaper.findIndex((citation)=>{
									return citation.citationData.citationUniqueID.toUpperCase() === citationUniqueID.toUpperCase();
								});

								if(findCitationIndex !== -1){
									store.commit('paperEdit/citations/REFRESH_AT_INDEX', {
										$thisCitationEl: $thisCitationEl,
										findIndex: findCitationIndex,
										citationUniqueID: citationUniqueID,
										isFirstUse: (i === 0) ? true : false,
										updatedReference: updatedReference		
									});
								}
							}//e:for
							
						}//e:if.count	
					});//e:forEach

				}//e:if
			});//e:forEach:reference
		}//e:if:else

		return resolve();
		
	});//e:Promise
}