// RS_ExtractPrimaryTitleFromReferenceData
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';

// https://docs.google.com/document/d/1aLasqUMtdm53VV-hh-b2qp9DpcM-6jWjhRUwC9Bpv3U/edit 
// the title properties to check for in order
const CHECK_TITLE_PROPERTY_ARRAY = [
    'articleTitle',
    'entryTitle',
    'chapterTitle',
    'episodeTitle',
    'description',
    'songTitle',
    'pageTitle',
    'workTitle',
    'sectionTitle',
    'issueTitle',
    'title',
    'translatedTitle',
    'anthologyTitle',
    'blogTitle',
    'bookTitle',
    'journalTitle',
    'seriesTitle',
    'albumTitle',
    'websiteTitle'
]

export default (referenceData) =>{
    // console.log('RS_ExtractPrimaryTitleFromReferenceData');
    let returnTitle ='';

    let referenceDataJSON = JSON.parse(referenceData);
    
    // loop through each title property
    _forEach(CHECK_TITLE_PROPERTY_ARRAY, (checkTitleProperty)=>{
        if(_has(referenceDataJSON, checkTitleProperty) && referenceDataJSON[checkTitleProperty] !== ''){
            returnTitle = referenceDataJSON[checkTitleProperty];
            return false;   // stop looking when you find one
        }
    });//forEach

    return returnTitle;
}