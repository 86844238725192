// RS_CalcCitationDisplayValueFromReferenceData
import _has from 'lodash/has';
import config from '@/config';
import Engine from '@/services/reference/Engine';
import store from '@/store';

export default (referenceData) =>{
    // console.log('RS_CalcCitationDisplayValueFromReferenceData');
        
    // calc mode to use when you call the Engine in the loop
    let modeLevel = 'available';
    if(config.isDev){
        modeLevel = 'dev';

    } else if(config.isTest){
        modeLevel = 'test';
        
    }
    
    // fake citation data to use when you call the Engine in the loop
    let blankCitationData = {
        'datePart': true,
        'label': true,
        'namePart': true,
        'shortTitle': '',
        'type': 'n.d.',
        'value': '',
    }

    let citationEngineResult = Engine.getCitation(referenceData.referenceTypeID, JSON.parse(referenceData.data), blankCitationData, modeLevel);

    if(
        store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7 &&
        _has(citationEngineResult, 'apa7') &&
        _has(citationEngineResult.apa7, 'first')
    ){
        return citationEngineResult.apa7.first;
    } else if(
        store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9 &&
        _has(citationEngineResult, 'mla9') &&
        _has(citationEngineResult.mla9, 'first')
    ){
        return citationEngineResult.mla9.first;
    } else if(
        store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9 &&
        _has(citationEngineResult, 'turabian9') &&
        _has(citationEngineResult.turabian9, 'subsequent')
    ){
        return citationEngineResult.turabian9.subsequent;
    }

    return '';
}



	